import { openWindow, makeRequest } from '@utils/request'

export const showOriginalArticle = async (ev) => {
	const articleId = location.pathname.split('/')[2]
	const response = await makeRequest(`/articles/${articleId}/original`, 'GET')
	const originalArticle = response.originalArticle

	const articleLayout = ev.target.closest('.article-layout-wrapper')
	articleLayout.querySelector('.title').textContent = originalArticle.title
}

export const toggleSitemap = (ev) => {
	ev.stopPropagation()

	const trigger = ev.target
	const sitemap = trigger.closest('header').querySelector('#sitemap')
	const overlay = document.getElementById('overlay')

	sitemap.classList.toggle('hidden')
	overlay.classList.toggle('hidden')
	document.body.classList.toggle('overflow-hidden')
}

export const toggleLayer = (ev) => {
	ev.stopPropagation()

	const trigger = ev.target
	const toolbar = trigger.closest('.toolbar')
	const targetSelector = trigger.getAttribute('data-target-selector') 
	const targetLayer = toolbar.querySelector(`.${targetSelector}`)

	targetLayer.classList.toggle('hidden')

	toolbar.querySelectorAll('.toolbar-layer').forEach((toolbarLayer) => {
		if (!toolbarLayer.classList.contains(targetSelector)) {
			toolbarLayer.classList.add('hidden')
		}
	})
}

export const clearSearchInput = (ev) => {
	const clearButton = ev.target
	const searchInput = clearButton.closest('.field').querySelector('.search-input')

	searchInput.value = ''
	searchInput.focus()

	clearButton.classList.add('hidden')
}

export const adjustFontSize = (ev) => {
	const fontSize = ev.target.getAttribute('data-font-size')
	document.querySelector('.content-container').className = `content-container fs-${fontSize}`
}

export const shareArticle = (ev) => {
	ev.target.closest('.sharing-layer').classList.add('hidden')

	const platform = ev.target.getAttribute('data-platform')

	switch (platform) {
		case 'facebook': {
			const url = encodeURIComponent(document.URL)
			openWindow(`https://www.facebook.com/sharer/sharer.php?u=${url}`, 500, 500)

			break
		}
		case 'x': {
			const url = encodeURIComponent(document.URL)
			const title = encodeURIComponent(document.querySelector('meta[property="og:title"]').getAttribute('content'))
			openWindow(`https://twitter.com/intent/tweet?url=${url}&text=${title}`, 500, 500)

			break
		}
		case 'linkedin': {
			const url = encodeURIComponent(document.URL)
			const title = encodeURIComponent(document.querySelector('meta[property="og:title"]').getAttribute('content'))
			openWindow(`https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${title}`, 500, 500)

			break
		}
		case 'url': {
			if (navigator.clipboard) {
				(async() => {
					await navigator.clipboard.writeText(document.location.href)
					alert('Copied to clipboard')
				})()
			}

			break
		}
		case 'print': {
			window.print()

			break
		}
	}
}


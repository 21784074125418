import Turbolinks from 'turbolinks'
import 'lazysizes'
import { 
	initClickTriggers,
	initAutoClosableElements,
	handleGnbOnScroll,
	throttle,
	initInfiniteScroll,
	initSearchInput
} from '@utils'
import * as clickHandlers from '@utils/clickHandlers'

Turbolinks.start()

document.addEventListener('turbolinks:load', async () => {
	initClickTriggers(clickHandlers)
	initAutoClosableElements()
	initSearchInput()

	if (window.innerWidth > 600) document.addEventListener('scroll', () => throttle(handleGnbOnScroll))

	const infiniteScrollList = document.getElementById('infinite-scroll-list')
	if (infiniteScrollList) initInfiniteScroll(infiniteScrollList)
})

export const makeRequest = async (url, method, payload = null) => {
	const options = {
		method: method.toUpperCase(),
		headers: {
			'Content-Type': 'application/json',
			'X-Requested-With': 'XMLHttpRequest'
		}
	}

	if (options.method !== 'GET') {
		options.headers['X-CSRF-Token'] = document.querySelector('meta[name=csrf-token]').getAttribute('content')
	}

	if (payload) {
		options.body = JSON.stringify(payload)
	}

	try {
		const response = await fetch(url, options)
		if (!response.ok) {
			const responseData = await response.json()
			throw new Error(responseData.errorMessage)
		}

		if (options.method === 'DELETE') return true

		return await response.json()
	} catch (error) {
		displayToast(error.message)
		throw error
	}
}

export const openWindow = (url, w = 600, h = 600) => {
	const left = window.top.outerWidth / 2 + window.top.screenX - ( w / 2)
	const __top = window.top.outerHeight / 2 + window.top.screenY - ( h / 2)
	const popupWindow = window.open(url, url, `scrollbars=yes, width=${w}, height=${h}, top=${__top}, left=${left}`)

	if (window.focus) popupWindow.focus()

	return popupWindow
}

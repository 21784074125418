import { makeRequest } from '@utils/request'

export const initClickTriggers = (clickHandlers) => {
	document.querySelectorAll('.click-trigger').forEach((el) => {
		el.addEventListener('click', (ev) => {
			const action = ev.target.getAttribute('data-click')
			if (action) clickHandlers[action](ev)
		})
	})
}

export const initAutoClosableElements = () => {
	const overlay = document.getElementById('overlay')
	document.addEventListener('click', (ev) => {
		document.querySelectorAll('.auto-closable').forEach((el) => {
			if (el === ev.target) return

			if (!el.contains(ev.target)) {
				el.classList.add('hidden')
				overlay.classList.add('hidden')
				document.body.classList.remove('overflow-hidden')
			}
		})
	})
}

export const handleGnbOnScroll = () => {
	const scrollTop = document.documentElement.scrollTop

	const navBar = document.getElementById('nav_bar')

	const gnb = navBar.querySelector('.category-list')
	const navLogo = navBar.querySelector('.nav-logo-image')
	const navArticleTitle = navBar.querySelector('.nav-article-title')

	let targetScrollTop = 150

	if (/articles\/[0-9]+/.test(document.URL)) {
		navArticleTitle.classList.toggle('invisible', scrollTop < targetScrollTop)
		gnb.classList.toggle('invisible', scrollTop > targetScrollTop)
	} else {
		if (navLogo.classList.contains('transitionable')) {
			navLogo.classList.toggle('invisible', scrollTop < targetScrollTop)
		}
	}
}

export const initInfiniteScroll = (list) => {
	const loadingIndicator = list.closest('.article-list-section').querySelector('#loading-indicator')

	if (!list.classList.contains('scrollable')) return

	let page = 2 

	const loadMoreItems = async () => {
		const params = new URLSearchParams(window.location.search)
		params.set('page', page)

		const requestUrl = `${window.location.pathname}?${params.toString()}`

		const response = await makeRequest(requestUrl, 'GET')
		list.insertAdjacentHTML('beforeend', response.articleItemsHtml)

		if (response.existNext) {
		} else {
			list.classList.remove('scrollable')
		}

		page++

		document.getElementById('loading-indicator').style.visibility = 'hidden'
	}

	const observer = new IntersectionObserver((entries, observer) => {
		entries.forEach(entry => {
			if (entry.isIntersecting && list.classList.contains('scrollable')) {

				document.getElementById('loading-indicator').style.visibility = 'visible'

				setTimeout(() => {
					loadMoreItems()
				}, 900)
			}
		})
	}, {
		root: null,
		threshold: 0.9
	})

	observer.observe(loadingIndicator)
}

export const initSearchInput = () => {
	const clearButton = document.querySelector('.sidebar .clear-button')

	document.querySelector('.search-input').addEventListener('input', (ev) => {
		clearButton.classList.toggle('hidden', ev.target.value.length <= 0)
	})
}

export const throttle = (callback) => {
	let ticking = false
	if (!ticking) {
		window.requestAnimationFrame(() => {
			callback()
			ticking = false
		})
		ticking = true
	}
}

